import React from "react";
import * as classes from "../../../SCSS/content.module.scss";

import MainPageHeader from "../PageHeaders/MainPageHeader";
import MainPageLayout from "../../Layout/MainPageLayout";

export default function MainPageTemplate(props) {
  return (
    <MainPageLayout
      selected={props.selected}
      pageTitle={props.pageTitle}
      pageDescription={props.pageDescription}
    >
      <MainPageHeader
        selected={props.selected}
        pageHeading={props.pageHeading}
        pageDescription={props.pageDescription}
      />

      <div className={classes.mainContentContainer}>{props.children}</div>
    </MainPageLayout>
  );
}
