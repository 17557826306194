import { CaretDown } from "phosphor-react";
import React from "react";
import * as styles from "./Dropdown.module.scss";

export default function Dropdown(props) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.filterLabel}>{props.filterDropdownLabel}</div>
      <div
        className={styles.dropdown}
        onClick={props.onDropdownClick}
        onKeyDown={props.onKeyDown}
        aria-label={props.filterDropdownLabel}
        style={{
          borderColor: props.dropdownActive
            ? "var(--grey-400)"
            : "var(--grey-500)",
        }}
      >
        {props.currentValueLabel}
        <div
          className={styles.dropdownOptionContainer}
          style={{
            display: props.dropdownActive ? "flex" : "none",
          }}
        >
          {props.options &&
            props.options.map((el) => (
              <div
                id={el.id}
                className={styles.dropdownOption}
                onClick={props.onDropdownItemClick}
                style={{
                  backgroundColor:
                    props.activeItem === el.id ? "var(--grey-500)" : null,
                }}
              >
                {el.label}
              </div>
            ))}
        </div>
        <div className={styles.dropdownCaret}>
          <CaretDown
            size={16}
            weight="bold"
            color={"var(--text-color-primary)"}
          />
        </div>
      </div>
    </div>
  );
}
