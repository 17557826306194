// extracted by mini-css-extract-plugin
export var bodyText = "content-module--body-text--0Y0xI";
export var bodyTextBold = "content-module--body-text-bold--UaHOe";
export var dissolveIn = "content-module--dissolveIn--sIsfE";
export var divider = "content-module--divider--b7KL7";
export var heading1 = "content-module--heading1--IuqN7";
export var heading2 = "content-module--heading2--ghiuG";
export var heading3 = "content-module--heading3--T8EQm";
export var heading4 = "content-module--heading4--kdGLP";
export var largeLinkText = "content-module--large-link-text--Lt-WI";
export var linkText = "content-module--link-text--ds514";
export var list = "content-module--list--RLDqI";
export var listBasic = "content-module--list-basic--H-QBb";
export var mainContentContainer = "content-module--main-content-container--Mxzcw";
export var mediumSpacerBottom = "content-module--medium-spacer-bottom--so5vO";
export var smallBodyText = "content-module--small-body-text--Fwxpp";
export var smallSpacerBottom = "content-module--small-spacer-bottom--u22xB";
export var smallSpacerLeft = "content-module--small-spacer-left--C36UN";
export var subtitle = "content-module--subtitle--HV5VQ";