import React from "react";
import * as classes from "./PageHeading.module.scss";
import * as styles from "../../../SCSS/content.module.scss";

export default function pageheading(props) {
  return (
    <h1 className={[classes.heading, styles.heading1].join(" ")}>
      {props.text}
    </h1>
  );
}
