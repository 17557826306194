// extracted by mini-css-extract-plugin
export var badgeMetadataContainer = "PageHeader-module--badge-metadata-container--zEXCP";
export var badgesContainer = "PageHeader-module--badges-container--534Ce";
export var dissolveIn = "PageHeader-module--dissolveIn--Wuy65";
export var header = "PageHeader-module--header--SAxKP";
export var headerContent = "PageHeader-module--header-content--9EYmS";
export var heading = "PageHeader-module--heading--ELjO6";
export var mainPageHeader = "PageHeader-module--main-page-header--fDYEz";
export var metadataContainer = "PageHeader-module--metadata-container--o4cqq";
export var smallHeaderImage = "PageHeader-module--smallHeaderImage--jjg2K";
export var subtitleAnimationContainer = "PageHeader-module--subtitleAnimationContainer--HJlEd";