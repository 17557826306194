import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "../SCSS/design-blog.module.scss";
import { CardBase, BlogCardContent } from "../components/Cards/Card";
import MainPageTemplate from "../components/Templates/PageTemplates/MainPageTemplate";
import { blog } from "../NavItems";
import useTopicFilter from "../../CustomFilterHook";

export const readTimeCalculator = (body) => {
  return `${Math.round(body.split(" ").length / 160)} min read`;
};

const MyBlogPage = () => {
  const { filterOptions, filteredTopicIndex, TopicDropdown } = useTopicFilter();

  const data = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { order: DESC, fields: datePublished }) {
        nodes {
          id
          title
          postDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          postBody {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          topics {
            topics
          }

          datePublished(formatString: "D MMMM YYYY")
          slug
        }
      }
    }
  `);

  return (
    <MainPageTemplate
      selected={blog}
      pageTitle={blog}
      pageHeading="Design Blog."
      pageDescription={`Check out some of my recent posts on UX design, product design, front-end development and more.`}
    >
      <div className={styles.blogCardsContainer}>
        <TopicDropdown />
        {data.allContentfulPost.nodes
          .filter((el) => {
            if (
              filteredTopicIndex === 0 ||
              el.topics.topics.includes(filterOptions[filteredTopicIndex].label)
            ) {
              return el;
            }
          })
          .map((post, i) => {
            return (
              <CardBase key={i + 1} toLink={`./${post.slug}`}>
                <BlogCardContent
                  title={post.title}
                  description={
                    post.postDescription.childMarkdownRemark.rawMarkdownBody
                  }
                  datePublished={post.datePublished}
                  topics={post.topics.topics}
                  readTime={readTimeCalculator(
                    post.postBody.childMarkdownRemark.rawMarkdownBody
                  )}
                />
              </CardBase>
            );
          })}
      </div>
    </MainPageTemplate>
  );
};

export default MyBlogPage;
