import React, { useState } from "react";

import Dropdown from "./src/components/Dropdown/Dropdown";
export default function useTopicFilter() {
  const [filterDropdownActive, setFilterDropdownActive] = useState(false);

  //filterOptions are hardcoded for this specific filter for the moment, in the future they could be passed as a prop to the hook instead?
  // ie options = {props.filterOptions}

  const filterOptions = [
    { label: "All Topics", id: 0 },
    { label: "UX Design", id: 1 },
    { label: "UI Design", id: 2 },
    { label: "Front-end Development", id: 3 },
    { label: "User Research", id: 4 },
  ];
  const [filteredTopicIndex, setFilteredTopicIndex] = useState(
    filterOptions[0].id
  );
  const [filteredTopicName, setFilteredTopicName] = useState(
    filterOptions[0].label
  );

  const handleFilterSelection = (e) => {
    const id = parseInt(e.target.getAttribute("id"));
    setFilteredTopicIndex((old) => (old = id));
    setFilteredTopicName(filterOptions[id].label);
  };

  const TopicDropdown = () => {
    return (
      <Dropdown
        activeItem={filteredTopicIndex}
        options={filterOptions}
        dropdownActive={filterDropdownActive}
        onDropdownClick={() => setFilterDropdownActive((old) => !old)}
        onKeyDown={(e) => e.keyCode === 27 && setFilterDropdownActive(false)}
        onDropdownItemClick={(e) => handleFilterSelection(e)}
        currentValueLabel={filteredTopicName}
        filterDropdownLabel="Choose a topic:"
      />
    );
  };

  return {
    filterOptions: filterOptions,
    handleFilterSelection: handleFilterSelection,
    filteredTopicIndex: filteredTopicIndex,
    filteredTopicName: filteredTopicName,
    filterDropdownActive: filterDropdownActive,
    setFilterDropdownActive: setFilterDropdownActive,
    TopicDropdown: TopicDropdown,
  };
}
