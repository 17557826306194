import React from "react";
import PageHeading from "../PageHeading/PageHeading";

import * as classes from "../../../SCSS/content.module.scss";
import * as styles from "./PageHeader.module.scss";
import { about } from "../../../NavItems";

export default function MainPageHeader(props) {
  return (
    <>
      <header className={[styles.mainPageHeader].join(" ")}>
        <PageHeading text={props.pageHeading} />
        <div className={styles.subtitleAnimationContainer}>
          <p className={classes.subtitle}>{props.pageDescription}</p>
        </div>
      </header>
      {props.selected === about ? (
        <div className={classes.divider}></div>
      ) : null}
    </>
  );
}
